@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-header > div {
  display: flex;
  flex-direction: row;
}

.card-header > div > .spacer {
  flex-grow: 1;
}

.org-view {
  margin-top: 10px;
}

body {
  height: 100vh;
}

#root {
  display: block;
  height: 100%;
}

.top-level {
  flex-direction: column;
  display: flex;
  height: 100%;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.expand-flex {
  flex: 1;
}

.nav-pills > .nav-item {
  margin: 5px;
}

.org-box {
  margin: 20px 0;
}

.date-block {
  margin: 5px;
}

.chart-container {
  height: 80vh;
}

.metric-bar.row {
  margin: 20px 0 0 0;
}

.metric-card .metric-value {
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 36px;
}

.metric-card .metric-value.display-5 {
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 24px;
}

.metric-card .metric-label {
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: bold;
  font-family: sans-serif;
  text-transform: uppercase;
  color: #a0a0a0;
}

.wearable-list-card {
  margin-bottom: 10px;
}

.btn.toolbar-hidden-button {
  padding: 0;
}